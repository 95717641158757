import React, { useContext, useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import CategoryList from "./CategoryList";
import {
  SearchContext,
  ArticleContext,
  AutherContext,
  KBContext,
  CategoryContext,
} from "../../Context";
import ArticleSearch from "../../Components/ArticleSearch/ArticleSearch";
import Layout from "../../Components/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import { CategoryLoader } from "../../Components/Loaders/CategoryLoader";
import { handleGainSightEventForPageNotFound } from "../../gainSightIntegration";

const ArticleWrapper = styled.section`
  display: flex;
  box-sizing: border-box;
  margin: 0 180px;
  margin-top: 48px;

  align-content: center;
  justify-content: center;
  flex-direction: row;

  @media screen and (max-width: 1100px) {
    margin: 0 90px;
    margin-top: 28px;
  }

  @media screen and (max-width: 800px) {
    margin: 0 50px;
    margin-top: 18px;
  }

  @media screen and (max-width: 500px) {
    margin: 0 24px;
    margin-top: 32px;
  }

  transition: margin 1s;
`;

export default function Article() {
  const { searchList, searchInput } = useContext(SearchContext);
  const { pathname } = useLocation();
  const ref = useRef();
  const { data } = useContext(KBContext);
  const { isInternal } = data;
  const { redirectToken, hasPermission } = useContext(AutherContext);

  const showArticle = () => {
    if (isInternal !== undefined && !isInternal) {
      return true;
    } else {
      return hasPermission;
    }
  };

  const navigate = useNavigate();

  const { setCategorySlug, setArticleSlug, articleData, selectedArticle } =
    useContext(ArticleContext);
  const { categoryList } = useContext(CategoryContext);

  useEffect(() => {
    if (!redirectToken && isInternal && !hasPermission) {
      localStorage.clear();
      navigate("/login");
    }
  }, [isInternal, hasPermission, redirectToken, navigate]);

  useEffect(() => {
    const pathArr = pathname.split("/");
    if (pathArr.length === 2 || (pathArr.length === 3 && !pathArr[2])) {
      setCategorySlug(pathArr[1]);
      setArticleSlug(false);
      const isCategoryPresent = categoryList.some(category => category.slug === pathArr[1]);
      if(!isCategoryPresent) {
        handleGainSightEventForPageNotFound(window.location.href);
        navigate("/not-found");
      }
    } else if (pathArr.length === 3) {
      setCategorySlug(pathArr[1]);
      setArticleSlug(pathArr[2]);
    } else {
      navigate("/");
      setCategorySlug(false);
      setArticleSlug(false);
    }
  }, [pathname]);

  useEffect(() => {
    const pathArr = pathname.split("/");
    if (pathArr.length === 2) {
      if (articleData) {
      } else {
        handleGainSightEventForPageNotFound(window.location.href);
        navigate("/not-found");
      }
    } else if (pathArr.length === 3) {
      if (selectedArticle) {
      } else {
        handleGainSightEventForPageNotFound(window.location.href);
        navigate("/not-found");
      }
    } else {
      setCategorySlug(false);
      setArticleSlug(false);
    }
  }, [articleData, selectedArticle]);

  function focus() {
    ref?.current?.focus();
  }

  return showArticle() ? (
    <Layout>
      {searchInput?.length > 0 && searchList ? (
        <ArticleSearch />
      ) : (
        <ArticleWrapper ref={ref}>
          <CategoryList focus={focus} />
          <Outlet />
        </ArticleWrapper>
      )}
    </Layout>
  ) : (
    <CategoryLoader />
  );
}
