import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { trimText } from "../../Constants/utility";
import { KBContext } from "../../Context";
import TooltipIcon from "../../Components/StyledComponents/Tooltip";

const CategoriesWrapper = styled.section`
  min-width: 187px;
  min-height: 266px;
  @media screen and (max-width: 778px) {
    display: none;
    visiblity: none;
  }
`;
const CategoryHeader = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: #666b7d;
  margin-bottom: 16px;
  cursor: pointer;
`;
const CategoryBody = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Label = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #313c4b;
  cursor: pointer;

  &.selected {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #222222;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const CategoryStyles = styled.li`
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.138889px;
  margin-bottom: 20px;
  cursor: pointer;
`;

export default function CategoriesSider({
  categories,
  handleCategoryClick,
  pathname,
}) {
  const navigate = useNavigate();

  return (
    <CategoriesWrapper>
      <CategoryHeader
        onClick={() => {
          document.getElementById("mainWrapper")?.focus();
          navigate("/");
        }}
      >
        Categories
      </CategoryHeader>
      <CategoryBody>
        {categories?.map((cat) => {
          return (
            <React.Fragment key={cat?.slug}>
              {cat.title.length > 20 ? (
                <CategoryStyles>
                  <TooltipIcon
                    className="bottom-center"
                    content={cat.title}
                    size="150px"
                  >
                    <Label
                      onClick={() => handleCategoryClick(cat)}
                      className={
                        pathname.split("/")[1] === cat?.slug && "selected"
                      }
                    >
                      {trimText(cat?.title, 20)}
                    </Label>
                  </TooltipIcon>
                </CategoryStyles>
              ) : (
                <CategoryStyles>
                  <Label
                    onClick={() => handleCategoryClick(cat)}
                    className={
                      pathname.split("/")[1] === cat?.slug && "selected"
                    }
                  >
                    {" "}
                    {cat.title}
                  </Label>
                </CategoryStyles>
              )}
            </React.Fragment>
          );
        })}
      </CategoryBody>
    </CategoriesWrapper>
  );
}
