import { useState } from "react";
import { ToastContext } from "../index";

export default function ToastProvider(props) {
  const [showToast, setShowToast] = useState(false);
  const [articleUrl, setArticleUrl] = useState(false);

  const [noNet, setNoNet] = useState(false);

  return (
    <ToastContext.Provider
      value={{
        showToast,
        setShowToast,
        articleUrl,
        setArticleUrl,
        noNet,
        setNoNet,
      }}
    >
      {props.children}
    </ToastContext.Provider>
  );
}
