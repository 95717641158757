import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import NoPageFound from "../NoPageFound";
import Article from "../Articles/index";
import ArticleList from "../Articles/ArticleList";
import PreviewArticle from "../Articles/PreviewArticle";
import SubmitIssue from "../SubmitIssue/SubmitIssue";
import Categories from "../Categories/Categories";
import LoginPage from "../Login/LoginPage";

export default function Home() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Categories />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="submit-issue" element={<SubmitIssue />} />
          <Route path={"not-found"} element={<NoPageFound />} />
          <Route element={<Article />}>
            <Route path="/:id" element={<ArticleList />} />
            <Route path="/:id/:id" element={<PreviewArticle />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
