import React from "react";
import { Helmet } from "react-helmet-async";
import { checkHTML, extractMetaTitleDescription } from "../../Constants/utility";
import parse from 'html-react-parser';

//React helmet will simply wrap all its children and place it in head of html
export const MetaTags = ({ title, description, logo, favIcon, type, customMeta }) => {
    const Image_Base_Url = process.env.REACT_APP_IMAGE_BASE_URL;

    const { metaTitle, metaDescription } = extractMetaTitleDescription(customMeta, title, description);

    return (
        <Helmet prioritizeSeoTags>
            {/* Standard metadata tags */}
            {metaTitle ? <title>{metaTitle}</title> : null}
            {metaDescription ? <meta name="description" content={metaDescription} /> : null}
            {favIcon ? <link rel="icon" href={Image_Base_Url + favIcon} /> : null}
            {/* End standard metadata tags */}
            {/* Facebook tags */}
            {type ? <meta property="og:type" content={type} /> : null}
            {metaTitle ? <meta property="og:title" content={metaTitle} /> : null}
            {metaDescription ? <meta property="og:description" content={metaDescription} /> : null}
            {logo ? <meta name="og:image" content={`${Image_Base_Url}${logo}`} /> : null}
            {/* End Facebook tags */}
            {/*  Not using dompurify for below customMeta item because it is manually added by user via grexitadminpanel */}
            {/* Hope the user knows what he is doing */}
            {checkHTML(customMeta) ? parse(customMeta) : null}
        </Helmet>
    );
};
