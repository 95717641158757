import React from "react";
import styled from "styled-components";
import {
  Label,
  FormControl,
  InputWrapper,
  Input,
  TextArea,
  StyledButton,
} from "../../Components/StyledComponents/StyledComponents";
import { lightOrDark } from "../../Constants/utility";
import ButtonLoader from "../Loaders/ButtonLoader";

const FormWrapper = styled.section`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  margin: 0 484px;
  margin-top: 35px;
  position:sticky;
  @media screen and (max-width: 1250px) {
    margin: 0 200px;
    margin-top: 35px;
  }

  @media screen and (max-width: 1100px) {
    margin: 0 100px;
    margin-top: 35px;
  }

  @media screen and (max-width: 768px) {
    margin: 0 50px;
    margin-top: 35px;
  }

  @media screen and (max-width: 500px) {
    margin: 0 24px;
    margin-top: 35px;
  }

  transition: margin 1s;
`;

const Button = styled(StyledButton)`
  position: relative;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  min-width: 150px;
  height: 48px;
`;

export default function SubmitIssue(props) {
  return (
    <FormWrapper>
      <FormControl>
        <Label htmlFor="title" onClick={(e) => e.preventDefault()}>
          Name*
        </Label>
        <InputWrapper direction="column" position="relative">
          <Input
            width="auto"
            onChange={props.handleTittleChange}
            value={props.formData.name}
            className={props.formDataError.nameError && "error"}
            type="text"
            id="title"
            autoComplete="off"
          />

          {props.formDataError.nameError && (
            <Label marginBottom="0px" className="label-info error">
              {props.formDataError.nameError}
            </Label>
          )}
        </InputWrapper>
      </FormControl>
      <FormControl>
        <Label htmlFor="email" onClick={(e) => e.preventDefault()}>
          Email*
        </Label>
        <InputWrapper direction="column" position="relative">
          <Input
            width="auto"
            onChange={props.handleEmailChange}
            value={props.formData.email}
            className={props.formDataError.emailError && "error errorWithIcon"}
            type="text"
            id="email"
            onBlur={props.checkEmail}
            autoComplete="off"
          />
          {props.formDataError.emailError && (
            <Label className="label-info error">
              {props.formDataError.emailError}
            </Label>
          )}
        </InputWrapper>
      </FormControl>
      <FormControl>
        <Label htmlFor="subject">Subject*</Label>
        <InputWrapper direction="column" position="relative">
          {props.formData.subject.length > 0 ||
          props.formDataError.subjectError ? (
            <Label
              className={`label-info maxlengthLabel ${
                props.formData.subject.length >= 30 ||
                props.formData.subject.length === 0
                  ? "maxlengthLabelError"
                  : ""
              }`}
            >
              {props.formData.subject.length}/30
            </Label>
          ) : null}
          <Input
            width="auto"
            onChange={props.handleSubjectChange}
            value={props.formData.subject}
            className={props.formDataError.subjectError && "error"}
            type="text"
            id="subject"
            //placeholder="acme knowledge base"
            autoComplete="off"
          />

          {props.formDataError.subjectError && (
            <Label className="label-info error">
              {props.formDataError.subjectError}
            </Label>
          )}
        </InputWrapper>
      </FormControl>
      <FormControl>
        <Label htmlFor="description" onClick={(e) => e.preventDefault()}>
          Describe your issue*
        </Label>
        <InputWrapper direction="column" position="relative">
          {props.formData.query.length > 0 || props.formDataError.queryError ? (
            <Label
              className={`label-info textAreaMaxlengthLabel ${
                props.formData.query.length >= 200 ||
                props.formData.query.length === 0
                  ? "maxlengthLabelError"
                  : ""
              }`}
            >
              {props.formData.query.length}/200
            </Label>
          ) : null}
          <TextArea
            width="auto"
            rows="5"
            onChange={props.handleDescriptionChange}
            value={props.formData.query}
            className={props.formDataError.queryError && "error"}
            type="text"
            id="description"
            autoComplete="off"
          />
          {props.formDataError.queryError && (
            <Label className="label-info error">
              {props.formDataError.queryError}
            </Label>
          )}
        </InputWrapper>
      </FormControl>
      <Button
        textColor={
          props?.brandColor && lightOrDark(props?.brandColor) === "dark"
            ? "#FFFFFF"
            : "#222222"
        }
        color={props.brandColor}
        submit="true"
        onClick={props.handleSubmit}
        primary
        className={props.formValidation() && "disableInSubmit"}
      >
        {props?.btnClick ? (
          <ButtonLoader h="20px" w="20px" r="2px" color={props.brandColor} />
        ) : (
          "Submit"
        )}
      </Button>
    </FormWrapper>
  );
}
