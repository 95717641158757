import React, { useContext, useEffect } from "react";
import { createPortal } from "react-dom";
import tooltipIcon from "../../images/tooltipIcon.svg";
import { ToastContext } from "../../Context";
import { toastTimeoutTime } from "../../Constants";
import CloseIcon from "./CloseIcon";
import { ToastWrapper, ToastLogoIcon, ToastContent, ArticleLink } from "./Toast.styles";

export const ToastContainer = () => {
  const { showToast, setShowToast, articleUrl, setArticleUrl } =
    useContext(ToastContext);

  useEffect(() => {
    const toastRef = setTimeout(() => {
      setShowToast(false);
      setArticleUrl(false);
    }, toastTimeoutTime);

    return () => clearTimeout(toastRef);
  }, [showToast, setArticleUrl, setShowToast]);

  if (showToast) {
    return createPortal(
      <ToastWrapper>
        <ToastLogoIcon src={tooltipIcon} />
        <ToastContent>
          {showToast}{" "}
          {articleUrl && (
            <ArticleLink
              onClick={() => {
                window.open(articleUrl, "_blank");
              }}
            >
              View
            </ArticleLink>
          )}
        </ToastContent>
        <CloseIcon
          margin="0 0 0 10px"
          background="#ECEFF6"
          onClick={() => setShowToast(false)}
        />
      </ToastWrapper>,
      document.getElementById("root")
    );
  }
  return null;
};
