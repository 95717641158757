import { handleGainSightEventForPageVisit } from './gainSightIntegration';
import { KB_TYPE } from './Constants/KBContext.constants';

export const handlePageVisitData = (location, isInternal) => {
    const pageVisitData = {
      pageUrl: location.href,
      pageBaseUrl: `${location.protocol}//${location.host}`,
      kbType: isInternal ? KB_TYPE.INTERNAL : KB_TYPE.EXTERNAL,
    }
    handleGainSightEventForPageVisit(pageVisitData);
}

export const isFeatureEnabled = (feature, features = {}) => {
  return features?.[feature] === true;
}

export const REDIRECT_TO_HOMEPAGE_MESSAGE = "Redirecting to Home in {{countdown}} second{{plural}}";