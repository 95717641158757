import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { REDIRECT_TO_HOMEPAGE_MESSAGE } from "../utils";

const RedirectingTextWrapper = styled.h3`
  font-weight: 450;
  font-size: 16px;
  line-height: 48px;
  margin: 0;
  padding: 0;
  margin-top: 12px;
`;

const CountdownComponent = () => {
  const [countdown, setCountdown] = useState(3);
  const intervalRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setCountdown((previous) => {
        if (previous <= 1) {
          clearInterval(intervalRef.current);
        }
        return previous - 1;
      });
    }, 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      navigate("/");
    }
  }, [countdown, navigate]);

  const isPlural = countdown !== 1 ? "s" : "";
  const redirectMessage = REDIRECT_TO_HOMEPAGE_MESSAGE.replace("{{countdown}}", countdown).replace("{{plural}}", isPlural);

  return (
    <RedirectingTextWrapper>
      {redirectMessage}
    </RedirectingTextWrapper>
  );
};

export default CountdownComponent;
