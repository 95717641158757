import React, { useState, useLayoutEffect, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import rightIcon from "../../images/rightIcon.svg";
import getStarted from "../../images/getStarted.svg";
import { trimText } from "../../Constants/utility";
import { Label } from "../StyledComponents/StyledComponents";
import TooltipIcon from "../StyledComponents/Tooltip";
import ButtonLoader from "../Loaders/ButtonLoader";
import ScrollToTop from "../../Components/ScrollToTop";
import { autoCapitaliseFirstLetter } from "../../Constants/utility";
import { KBContext } from "../../Context";

const ArticleListContainer = styled.section`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-left: 100px;
  position: relative;

  @media screen and (max-width: 1200px) {
    padding-left: 80px;
  }

  @media screen and (max-width: 1100px) {
    padding-left: 50px;
  }

  @media screen and (max-width: 778px) {
    padding-left: 0px;
  }

  transition: padding 1s;
`;
const ArticleBar = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: #f9f9f9;
  border-radius: 4px;
  margin-bottom: 25px;
  padding: 20px 26px 20px 20px;

  &:hover {
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 10px 0px;
  }
`;
const RightImage = styled.img`
  height: 9.55px;
  width: 5.83px;
`;
const CategoryContaiiner = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 29px;
`;
const CategoryTitleContainer = styled.div`
  width: 100%;
  display: flex;
  margin-left: 16px;
  box-sizing: border-box;
  flex-direction: column;
  overflow: hidden;
`;
const CategoryTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.138889px;
  color: #222222;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    overflow: auto;
    text-overflow: initial;
  }

  @media screen and (max-width: 500px) {
    font-size: 16px;
  }

  transition: font-size 1s;
`;
const CategoryDescription = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #313c4b;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    overflow: auto;
    text-overflow: initial;
  }

  @media screen and (max-width: 500px) {
    font-size: 13px;
    line-height: 20px;
  }

  transition: font-size 1s, line-height 1s;
`;
const WrapperDescription = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;    
  width: 100%;
  box-sizing: border-box;
  padding: 0 5px 0 0; 
`

export default function ArticleListing({
  articles,
  handleArticleClick,
  selectedCategory,
  kbdata,
}) {
  const [maxLength, setMaxLength] = useState(90);
  const Image_Base_Url = process.env.REACT_APP_IMAGE_BASE_URL;
  const originalDocTitle = useRef('');
  const { setArticleTitle } = useContext(KBContext);

  useLayoutEffect(() => {
    if (window.innerWidth > 1100) {
      setMaxLength(90);
    } else {
      setMaxLength(50);
    }
  });

  //maintain the order of useEffect

  useEffect(() => {
    setArticleTitle(null);
  }, []);

  useEffect(() => {
    originalDocTitle.current = document.title;
    return () => {
      document.title = originalDocTitle.current;
    };
  }, []);

  useEffect(() => {
    const docTitle = selectedCategory?.title;
    if (!docTitle) return;
    else {
      document.title = autoCapitaliseFirstLetter(docTitle);
    }
  });

  const CategoryIcon = (str) => {
    if (str === "getting-started-icon") {
      return (
        <img
          style={{ width: "40px", height: "40px" }}
          alt="categoryIcon"
          src={getStarted}
        />
      );
    }else if (str?.includes("media")) {
      return (
        <img
          style={{ width: "40px", height: "40px" }}
          alt="categoryIcon"
          src={Image_Base_Url + str}
        />
      );
    } else {
      return (
        <span
          style={{ fontSize: "40px", height: "40px", color: kbdata.brandColor }}
          className="material-icons-outlined">
          {str}
        </span>
      );
    }
  };
  return (
    <ArticleListContainer>
      <ScrollToTop />
      <CategoryContaiiner>
        {CategoryIcon(selectedCategory?.icon)}
        <CategoryTitleContainer>
          <CategoryTitle>{selectedCategory?.title}</CategoryTitle>
          <WrapperDescription>
            {selectedCategory?.description?.length > 60 ? (
              <TooltipIcon
                className="bottom-center"
                content={selectedCategory?.description}
                size="300px"
              >
                <CategoryDescription>
                  {trimText(selectedCategory?.description, 60)}
                </CategoryDescription>
              </TooltipIcon>
            ) : (
              <CategoryDescription>
                {selectedCategory?.description}
              </CategoryDescription>
            )}
          </WrapperDescription>
        </CategoryTitleContainer>
      </CategoryContaiiner>

      <Wrapper>
        {articles?.length === 0 ? (
          <LoaderWrapper>
            <ButtonLoader color="#484848" h="32px" w="32px" r="2px" />
          </LoaderWrapper>
        ) : (
          articles?.map((article) => {
            return (
              <ArticleBar
                key={article?.slug}
                onClick={() => handleArticleClick(article)}
              >
                {article?.title.length > maxLength ? (
                  <TooltipIcon
                    className="bottom-center"
                    content={article?.title}
                    size="300px"
                  >
                    <Label className="articleLabel">
                      {trimText(article?.title, maxLength)}
                    </Label>
                  </TooltipIcon>
                ) : (
                  <Label className="articleLabel">{article?.title}</Label>
                )}
                <RightImage src={rightIcon} />
              </ArticleBar>
            );
          })
        )}
      </Wrapper>
    </ArticleListContainer>
  );
}
