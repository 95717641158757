import { useState, useEffect, useCallback, useRef } from "react";
import { SearchContext } from "../index";
import { articleSearch } from "../../Service/ArticleService";

export default function ArticleProvider(props) {
  const [searchList, setSearchList] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const handleDebounceSearch = () => {
    articleSearch(searchInput).then((result) => {
      setSearchList(result.data.data.articles);
    });
  };

  useEffect(() => {
    if (searchInput.length === 0) {
      setSearchList(false);
    } else if (searchInput.length > 2) {
      handleDebounceSearch();
    }
  }, [searchInput]);

  return (
    <SearchContext.Provider
      value={{
        searchList,
        setSearchInput,
        setSearchList,
        searchInput,
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
}
