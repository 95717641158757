export const px2vw = (size, width = 1440) => `${(size / width) * 100}vw`;

export const validateEmail = (email) => {
  return /.+@.+\.[A-Za-z]+$/.test(email);
};

const urlPattern = new RegExp(
  "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
  "i"
);

export const validURL = (str) => !!urlPattern.test(str);

export const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 1000);
  };
};

export function validAsciiChar(str) {
  if (typeof str !== "string") {
    return false;
  }
  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 127) {
      return false;
    }
  }
  return true;
}

export const trimText = (text, maxLength) => {
  const value = text?.trim();
  if (value?.length > maxLength) {
    return value.substring(0, maxLength) + "...";
  }
  return value;
};

var element, bgColor, brightness, r, g, b, hsp;

export function lightOrDark(color) {
  // Check the format of the color, HEX or RGB?
  if (color?.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );
    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return "light";
  } else {
    return "dark";
  }
}

export const autoCapitaliseFirstLetter = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export const scrollToIdOrClass = (selector) => {
  selector && document.querySelector(selector)?.scrollIntoView({ behavior: 'smooth' });
}

export const checkHTML = (str) => {
  let div = document.createElement('div');
  div.innerHTML = str;
  // This return true if the child (code snippet) has a valid html
  for (let children = div.childNodes, index = children.length; index--;) {
    if (children[index].nodeType == 1) return true;
  }
  return false;
}

// Utility function to extract <title> and <meta name="description"> from customMeta
export const extractMetaTitleDescription = (customMeta, title, description) => {
  if (!customMeta || !checkHTML(customMeta)) return { metaTitle: title || null, metaDescription: description || null };
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = customMeta;

  const titleTag = tempDiv.querySelector('title');
  const titleContent = titleTag ? titleTag.textContent : title;

  const metaDescriptionTag = tempDiv.querySelector('meta[name="description"]');
  const descriptionContent = metaDescriptionTag ? metaDescriptionTag.getAttribute('content') : description;
  return { metaTitle: titleContent, metaDescription: descriptionContent };
};

const loadSequentially = async (arr, idx = 0) => {
  let script = document.createElement('script');
  const item = arr[idx];
  if (item.src) {
    script.src = item.src;
  } else {
    script.type = "text/javascript";
  }
  if (item.defer) script.defer = !!item.defer;
  // Forcefully we are defaulting to defer if both async and defer are present. 
  // if the user knows what he is doing, he should only keep async or defer and not both.
  else if (item.async) script.async = !!item.async;
  if (item.getAttribute('data-domain')) script.setAttribute('data-domain', item.getAttribute('data-domain'));
  script.innerHTML = item.innerHTML;
  
  document.body.appendChild(script);
  if (arr[idx + 1]) {
    // we are loading all the scripts and not making it render blocking to load parallely to increase lcp.
    loadSequentially(arr, idx + 1);
  }
}

export function codeSnippet(snippet) {
  let code;
  snippet?.forEach((snip) => {
    const isValidHTML = checkHTML(snip?.code);
    if (isValidHTML) {
      // use the html directly
      const temp = document.createElement('div');
      temp.innerHTML = snip?.code;
      const arrayWithSourceScript = Array.from(temp.children).filter(item => item.src);
      const arrayWithoutSource = Array.from(temp.children).filter(item => !item.src);
      try {
        //first load the scripts with source and then load the ones without script. We support async and defer as of now
        loadSequentially([...arrayWithSourceScript, ...arrayWithoutSource]);
      }
      catch (e) {
        console.error('unable to append code snippet in script');
      }
    } else {
      // add the content inside a script and use it
      let snipWithoutTags = snip?.code?.replace(/(<([^>]+)>)/gi, "");
      let script = document.createElement("script");
      script.type = "text/javascript";
      code = snipWithoutTags;
      try {
        script.appendChild(document.createTextNode(code));
        document.body.appendChild(script);
      } catch (e) {
        script.text = code;
        document.body.appendChild(script);
      }
    }
  });
}
