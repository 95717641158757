import React from "react";
import ContentLoader from "react-content-loader";

export const CategoryLoader = (props) => {
  return (
    <ContentLoader viewBox="0 0 500 300" height="100%" width="100%" {...props}>
      <rect x="20" y="20" rx="2" ry="2" width="50" height="5" />
      <rect x="350" y="20" rx="2" ry="2" width="50" height="5" />
      <rect x="420" y="20" rx="2" ry="2" width="50" height="5" />

      <rect x="0" y="45" rx="0" ry="0" width="100%" height="120" />

      <rect x="220" y="180" rx="3" ry="3" width="60" height="7" />

      <rect x="105" y="200" rx="0" ry="0" width="90" height="90" />
      <rect x="205" y="200" rx="0" ry="0" width="90" height="90" />
      <rect x="305" y="200" rx="0" ry="0" width="90" height="90" />

      <rect x="105" y="300" rx="0" ry="0" width="90" height="90" />
      <rect x="205" y="300" rx="0" ry="0" width="90" height="90" />
      <rect x="305" y="300" rx="0" ry="0" width="90" height="90" />
    </ContentLoader>
  );
};
