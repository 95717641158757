
export const getDeviceType = () => {
  const userAgent = navigator.userAgent;
  const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i;
  if (mobileRegex.exec(userAgent)) {
    return 'Mobile';
  } else {
    return 'Desktop';
  }
};

function getUTMParameters(url) {
  const queryString = url.split("?")[1];
  if (!queryString) {
    return {};
  }
  const queryParams = queryString.split("&");
  let utmParameters = {};
  for (const queryParam of queryParams) {
    const pair = queryParam.split("=");
    if (pair[0].startsWith("utm_")) {
      utmParameters[pair[0]] = pair[1];
    }
  }

  return utmParameters;
}

function getVisitedFlag() {
  return localStorage.getItem("visited");
}

function setVisitedFlag() {
  localStorage.setItem("visited", "true");
}

export const isNewVisitor = () => {
  if (getVisitedFlag() === "true") return false;
  else {
    setVisitedFlag();
    return true;
  }
};

export const sendGainsightEvent = (event, data = {}) => {
  try {
    if (window.aptrinsic) {
      window.aptrinsic("track", event, data);
    }
  } catch (e) {
    console.error("Aptrinsic tracking call failed", e);
  }
};

export const handleGainSightEventForPageVisit = (data) => {
  const { utm_source, utm_medium, utm_campaign } = getUTMParameters(
    data.pageUrl
  );
  const eventData = {
    pageBaseUrl: data.pageBaseUrl,
    deviceType: getDeviceType(),
    utm_source: utm_source === undefined ? "" : utm_source,
    utm_medium: utm_medium === undefined ? "" : utm_medium,
    utm_campaign: utm_campaign === undefined ? "" : utm_campaign,
    newUser: isNewVisitor(),
    kbType: data.kbType,
  };
  sendGainsightEvent(KB_WEBSITE_GAINSIGHT_EVENTS.PAGE_VISIT, eventData);
};

export const handleGainSightEventForPageNotFound = (originalUrl) => {
  const pageNotFoundData = {
    originalUrl,
  };
  sendGainsightEvent(
    KB_WEBSITE_GAINSIGHT_EVENTS.PAGE_NOT_FOUND,
    pageNotFoundData
  );
};

export const KB_WEBSITE_GAINSIGHT_EVENTS = {
  PAGE_NOT_FOUND: "pageNotFoundTriggered",
  PAGE_VISIT: "pageVisit",
};
