import React from "react";
import styled from "styled-components";
import { keyframes } from "styled-components";

const Wrapper = styled.div`
  // position: absolute;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  width: ${(props) => props.w};
  height: ${(props) => props.h};
  border-radius: 50%;
  margin:auto;
`;

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  box-sizing: border-box;
  border-radius: 50%;
  border: ${(props) => props.r} solid ${(props) => props.color};
  border-top: ${(props) => props.r} solid white;
  width: ${(props) => props.w};
  height: ${(props) => props.h};

  -webkit-animation: ${spin} 2s linear infinite; /* Safari */
  animation: ${spin} 2s linear infinite;
`;

export default function ButtonLoader({ color, h, w, r }) {
  return (
    <Wrapper color={color} h={h} w={w} r={r}>
      <Loader color={color} h={h} w={w} r={r} />
    </Wrapper>
  );
}
