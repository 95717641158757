import { useState, useEffect, useContext, useCallback } from "react";
import { KBContext, ToastContext } from "../index";
import { codeSnippet } from "../../Constants/utility";
import { getRequest, getAvailableFeatures } from "../../apiRequest";
import { MetaTags } from "../../Components/MetaTags";

export default function KBProvider(props) {
  const { setNoNet, setShowToast } = useContext(ToastContext);
  const [data, setData] = useState(true);
  const [customMeta, setCustomMeta] = useState(null);
  const [showCategoryLoader, setShowCategoryLoader] = useState(false);
  const [hiddenState, setHiddenState] = useState(false);
  const [showDashboardFooter,setShowDashboardFooter] = useState(false);
  const [availableFeatures, setAvailableFeatures] = useState({});
  const [articleTitle, setArticleTitle] = useState(data?.title);

  useEffect(() => {
    setShowCategoryLoader(true);
    getRequest("knowledgebase")
      .then((res) => {
        setData(res?.data?.data?.knowledgeBaseInfo);
        setArticleTitle(res?.data?.data?.knowledgeBaseInfo?.title);
        setShowCategoryLoader(false);
        codeSnippet(res?.data?.data?.knowledgeBaseInfo?.codeSnippet);
      })
      .catch(() => {
        setData(false);
        setShowCategoryLoader(false);
      });
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setShowCategoryLoader(true);
      getRequest("knowledgebase")
        .then((res) => {
          setData(res?.data?.data?.knowledgeBaseInfo);
          setArticleTitle(res?.data?.data?.knowledgeBaseInfo?.title);
          setShowCategoryLoader(false);
          codeSnippet(res?.data?.data?.knowledgeBaseInfo?.codeSnippet);
        })
        .catch(() => {
          setData(false);
          setShowCategoryLoader(false);
        });
    },  60 * 60 * 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (!hiddenState) {
      setTimeout(() => {
        setHiddenState(true);
      }, 10000);
    }
  }, [hiddenState]);

  const fetchAvailableFeatures = useCallback(
    (subdomain) => {
      getAvailableFeatures(subdomain)
        .then((response) => {
          const {
            data: { availableFeatures: availableFtrs = {} },
          } = response?.data || {};
          setAvailableFeatures(availableFtrs);
        })
        .catch((rej) => {
          if (rej.message === "Network Error") {
            setNoNet(true);
          } else {
            setShowToast("Unable to fetch features for the account.");
          }
          console.error(rej);
        });
    },
    [setNoNet, setShowToast, setAvailableFeatures]
  );

  useEffect(() => {
    if (data?.subdomain) {
      fetchAvailableFeatures(data.subdomain);
    }
  }, [data, fetchAvailableFeatures]);

  return (
    <KBContext.Provider
      value={{
        data,
        setData,
        hiddenState,
        showCategoryLoader,
        setHiddenState,
        showDashboardFooter,
        setShowDashboardFooter,
        availableFeatures,
        customMeta,
        setCustomMeta,
        setArticleTitle,
      }}
    >
      <MetaTags
        title={articleTitle}
        favIcon={data?.favicon}
        description={data?.description}
        customMeta={customMeta}
        kbTitle={data?.title}
      />
      {props.children}
    </KBContext.Provider>
  );
}
