import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { validAsciiChar } from "../Constants/utility";
import { SearchContext } from "../Context";
import { px2vw } from "../Constants/utility";
import { Label } from "./StyledComponents/StyledComponents";
import searchIcon from "../images/searchIcon.svg";
import closeIcon from "../images/closeIcon.svg";

const Image_Base_Url = process.env.REACT_APP_IMAGE_BASE_URL;

const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.pathname === "/" ? "center" : "")};
  justify-content: center;

  width: 100%;
  box-sizing: border-box;
  padding: 0 180px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 1100px) {
    padding: 0 90px;
  }

  @media screen and (max-width: 800px) {
    padding: 0 50px;
  }

  @media screen and (max-width: 500px) {
    padding: 0 24px;
  }

  transition: padding 1s;
`;

const BannerWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  height: ${(props) =>
    props.pathname === "/" || props.pathname === "/submit-issue"
      ? "342px"
      : "240px"};
}

  background: url(${(props) =>Image_Base_Url + props.bannerData.bannerImage}) no-repeat;
  background-color: ${(props) =>
    !props.bannerData.bannerImage ? props.bannerData?.brandColor : ""};
  background-size: cover;
  background-position: center;

  @media (max-width: 700px) {
    height: ${(props) =>
    props.pathname === "/" || props.pathname === "/submit-issue"
      ? "282px"
      : "118px"};
  }
  overflow: hidden;
  
  transition: height 1s;
`;

const BannerInput = styled.input`
  box-sizing: border-box;
  width: 750px;
  outline: none !important;
  height: ${(props) => (props.pathname === "/" ? "69px" : "59px")};
  border: 0px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: -0.320374071598053px;
  text-align: left;
  padding: ${(props) =>
    props.pathname === "/" ? "0 16px 0 65px" : "0 16px 0 65px"};
  background: url(${searchIcon}) no-repeat;
  background-position: ${(props) =>
    props.pathname === "/" ? "2.5% 22px" : "2.5% 18px"};
  background-color: white;

  // margin-right: ${(props) => (props.bannerLeft ? "" : px2vw(150))};

  ::placeholder { 
   color: #8F97B2;
  }

  @media screen and (max-width: 1100px) {
    width: -webkit-fill-available;
  }

  @media screen and (max-width: 700px) {
    width: -webkit-fill-available;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
   //  color: #8f97b2;

    background-size: 24px 24px;

    height: 54px;
    background-position: 16px 16px;
    margin: 0;
    padding: 0 18px 0 56px;
  }

  @media screen and (max-width: 500px) {
    width: -webkit-fill-available;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
   //  color: #8f97b2;

    background-size: 20px 20px;

    height: 54px;
    background-position: 16px 16px;
    margin: 0;
    padding: 0 16px 0 48px;
  }

  transition: 1s;
`;

export const AbsoluteWrapper = styled.div`
  width: ${(props) => props.width || "100%"};
  position: ${(props) => props.position};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.top};
  bottom: ${(props) => props.left};
  z-index: ${(props) => props.zIndex};
  transform: ${(props) => props.transform};
  cursor: ${(props) => props.cursor};

  @media screen and (max-width: 1100px) {
    width: ${(props) => props.search === true && "fit-content"};
    width: ${(props) => props.pathname !== "/" && "100%"};
    top: ${(props) => (props.pathname === "/" ? props.top : "15px")};
  }

  @media screen and (max-width: 700px) {
    top: ${(props) => (props.pathname === "/" ? "15px" : "15px")};
  }

  @media screen and (max-width: 500px) {
    top: ${(props) => (props.pathname === "/" ? "14px" : "15px")};
  }

  transition: 1s;
`;

const HoverAbsolute = styled(AbsoluteWrapper)`
  display: flex;
  height: 30px;
  width: 30px !important;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &:hover {
    background: rgb(245, 245, 245);
  }
`;

export default function BannerSection({ bannerData }) {
  const { setSearchInput, searchInput, setSearchList } =
    useContext(SearchContext);

  const { pathname } = useLocation();

  const handleBannerInputChange = (e) => {
    if (validAsciiChar(e.target.value)) {
      setSearchInput(e.target.value);
    }
  };

  return (
    <BannerWrapper pathname={pathname} bannerData={bannerData}>
      <WrapperContent pathname={pathname}>
        {pathname.slice(1) === "" && (
          <Label
            padding={pathname.slice(1) === "submit-issue" && "0"}
            responsive
            bannerColor={bannerData.bannerTextColor}
            className="bannerText"
          >
            {bannerData.bannerText}
          </Label>
        )}

        {pathname.slice(1) === "submit-issue" && (
          <Label
            padding={pathname.slice(1) === "submit-issue" && "0"}
            responsive
            bannerColor={bannerData.bannerTextColor}
            className="bannerText"
          >
            {bannerData.helpPageHeading}
          </Label>
        )}

        {pathname.slice(1) === "submit-issue" ? (
          ""
        ) : (
          <>
            <AbsoluteWrapper
              position="relative"
              search={searchInput.length > 0}
              width={pathname !== "/" ? "fit-content" : "auto"}
            >
              <BannerInput
                bannerLeft={pathname.slice(1) === ""}
                pathname={pathname}
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={handleBannerInputChange}
              />
              {searchInput.length > 0 && (
                <HoverAbsolute
                  position="absolute"
                  width="auto !important"
                  cursor="pointer"
                  pathname={pathname}
                  right="20px"
                  top={pathname === "/" ? "20px" : "17px"}
                  onClick={() => {
                    setSearchInput("");
                    setSearchList(false);
                  }}
                >
                  <img
                     style={{ zIndex: 10, height: 20, width: 20 }}
                    src={closeIcon}
                  />
                </HoverAbsolute>
              )}
            </AbsoluteWrapper>
          </>
        )}
      </WrapperContent>
    </BannerWrapper>
  );
}
