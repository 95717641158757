import styled from "styled-components";

export const StartedWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 1366px;
  align-items: center;
  flex: 1;
  overflow: hidden;
  height: 100vh;
`;

export const ImageWrapper = styled.div`
  width: 50%;
  height: 100vh;
  background: #f5f7fa;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const SecondWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 115px;
  padding-right: 96px;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 34px;
  line-height: 150%;
  letter-spacing: -0.138889px;
  color: #09274a;
  margin: 0;
  padding: 0;
  font-family: "Inter";
`;

export const LogoWrapper = styled.div`
  width: 124px;
  height: 42.18px;
  margin-bottom: 20px;
`;

export const LogoImage = styled.img`
  width: 124px;
  height: 42.18px;
  margin-bottom: 20px;
`;

export const Label = styled.label`
    width: ${(props) => (props.width ? props.width : "100%")};
    line-height: 20px;
    font-weight: 500;
    font-size: 13px;
    color: #28476a;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    font-family: "Inter";
`;

export const LoginButton = styled.button`
  position: relative;
  font-family: "Poppins";
  display: "flex";
  width: 80px;
  height: 40px;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  margin-top: 24px;
  border-radius: 4px;
  outline: none !important;
  cursor: pointer;
  letter-spacing: 0.232143px;
  background-color: rgba(20, 115, 230, 1);
  border: 1px solid white;
  color: rgba(255, 255, 255, 1);

  &:hover {
    background-color: rgba(64, 146, 245, 1);
  }

  &.disabled {
    color: rgb(151, 163, 183);
    background-color: rgba(237, 239, 245, 1);
    pointer-events: none;
  }
`;

export const StyledInput = styled.input`
  width: 308px;
  height: 36px;
  border: 1px solid #d6dde8;
  border-radius: 4px;
  padding: 8px 8px 8px 10px;
  box-sizing: border-box;
  &::placeholder {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    font-family: Inter, sans-serif;
    letter-spacing: 0.25px;
  }
  &.error,
  &:focus.error {
    border: 1px solid #CD3746;
    outline: none;
  }
`;

export const ErrorMessage = styled.div`
  margin-top: 8px;
  color: #cd3746;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  text-align: left;
`;

export const FlexBox = styled.div`
  display: flex;
`;
