import styled from "styled-components";

export const ToastWrapper = styled.div`
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: center;

  bottom: 25px;
  left: 25px;

  padding: 16px;

  background-color: #2a323e;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 10000;
`;

export const ToastLogoIcon = styled.img`
  width: 20px;
  height: 23px;
`;

export const ToastContent = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  padding: 0 16px;
  margin: 0;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.23000000417232513px;
  text-align: left;
`;

export const ArticleLink = styled.div`
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: rgba(118, 153, 244, 1);
  margin-left: 20px;
`;
