import React from "react";
import { Wrapper, CenterWrapper, CrossLineInverse, CrossLine } from "./CloseIcon.styles";

export default function CloseIcon({ onClick, margin, background, size }) {
  return (
    <Wrapper margin={margin} onClick={onClick} size={size || "20px"}>
      <CenterWrapper>
        <CrossLineInverse background={background} />
        <CrossLine background={background} />
      </CenterWrapper>
    </Wrapper>
  );
}
