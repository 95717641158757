import _ from 'lodash';
import { DEFAULT_LOGO } from "./constants";
import { completeOAuth } from "../../Service/LoginServices";
import { newHiverLoginInit } from '../../Service/LoginServices';

export const getLogo = kbData => _.isObject(kbData) && kbData.logo !== DEFAULT_LOGO && kbData.logo;

const listenForMessageId = (frameUrl, id, callback) => {
    const onListen = (event) => {
        if (event.origin !== frameUrl) {
            return;
        }
        if (event.data.id !== id) {
            return;
        }
        callback(event.data);
    }

    window.addEventListener("message", onListen);
    return () => {
        window.removeEventListener("message", onListen);
    }
}

const createRandomString = (length) => {
    let val = '';
    for (let i = 0; i < length; i++) {
        const num = Math.random() * 26;
        const point = 65 + num;
        val += String.fromCharCode(point);
    }
    return val;
}

const decodeString = (text, key) => {
    let val = '';
    for (let i = 0; i < text.length; i++) {
        const charCode = text.charCodeAt(i);
        const keyIndex = i % key.length;
        const keyCode = key.charCodeAt(keyIndex);
        val += String.fromCharCode(charCode - keyCode);
    }
    return val;
}

const getUrlData = (url) => {
    const decodedUrl = decodeURIComponent(url);
    const urlObj = new URL(decodedUrl);
    return urlObj;
}

export const open_popup = (url, callback, email, setRefetchToken, setRedirectPage, setEmail, setShowError, setLoginInitiated) => {
    var popup = window.open(url, 'OpenID', 'width=1200 height=600 left=150 top=150');
    let hasDataReceived = false;
    const urlObj = getUrlData(url);
    const redirectUrl = urlObj.searchParams.get('redirect_uri');
    const targetUrlObj = getUrlData(redirectUrl);
    const targetDomain = targetUrlObj.origin;

    const messages = {
        authId: 'AUTH_TOKEN',
        closeId: 'CLOSE_WINDOW'
    }
    const randomKey = createRandomString(8);
    const closeMessageIdListener = listenForMessageId(targetDomain, messages.authId, (data) => {
        hasDataReceived = true;
        const { val } = data;
        const authToken = decodeString(val, randomKey);
        callback(authToken, email, setShowError, setRefetchToken, setLoginInitiated).then(() => {
            popup.postMessage({ id: messages.closeId }, targetDomain);
        });
    });

    // Check every 100 ms if the popup is closed.
    var finishedInterval = setInterval(function () {
        // If the popup is closed, we've either finished OpenID, or the user closed it. Verify with the server in case the
        // user closed the popup.
        if (!hasDataReceived) {
            try {
                if (popup) {
                    popup.postMessage({ id: messages.authId, key: randomKey }, targetDomain);
                } else {
                    //This is needed if user has blocked popups. So it will go up in infinite error loops since popup is unavailable
                    clearInterval(finishedInterval);
                }
            } catch (e) {
                console.error(e);
            }
        }
        if (popup && popup.closed) {
            hasDataReceived = true;
            closeMessageIdListener();
            clearInterval(finishedInterval);
            setRefetchToken(false);
            setRedirectPage(false);
            setEmail('');
        }
    }, 250);
};

export const onOAuthSuccess = (authToken, email, setShowError, setRefetchToken, setLoginInitiated) => {
    return new Promise((res) => {
        completeOAuth(authToken, email).then((resp) => {
            const { status: statusCode, data: { status, error_message } } = resp;
            if (statusCode === 200 && status !== 'error') {
                setRefetchToken(true);
            } else if (status === 'error' && error_message.includes('Incorrect email/token')) {
                // Handle different Email
                setShowError(true);
                setLoginInitiated(true);
            }
        }).catch((err) => {
            console.error("Login failed: ", err);
        }).finally(() => {
            res();
        });
    })
}

export const getGoogleAuthUrl = (email) => {
    // This is called once to get the redirect URL as soon as the user clicks login
    if (email) {
        return new Promise((resolve, reject) => {
            newHiverLoginInit({ email, json_mode: 1, _csrf_token: undefined })
                .then((res) => {
                    if (res.status === 200) {
                        const { redirect_url } = res?.data;
                        resolve(redirect_url);
                    } else {
                        reject(new Error("Login init failed"));
                    }
                })
                .catch((err) => {
                    console.error(err);
                    reject("");
                });
        });
    }

    return new Promise((_res, reject) => {
        const errorMessage = "No Email Present";
        console.error(errorMessage);
        reject(new Error(errorMessage));
    });
};
