import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PreviewArticleComp from "../../Components/Articles/PreviewArticle";
import { ArticleContext, CategoryContext } from "../../Context";
import ScrollToTop from "../../Components/ScrollToTop";

export default function PreviewArticle() {
  const { selectedArticle } = useContext(ArticleContext);

  const { selectedCategory } = useContext(CategoryContext);
  const { pathname } = useLocation();

  return (
    <>
      <ScrollToTop />
      <PreviewArticleComp
        pathname={pathname}
        article={selectedArticle}
        categoryTitle={selectedCategory?.title}
        categoryIcon={selectedCategory?.icon}
      />
    </>
  );
}
