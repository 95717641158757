import { getRequest } from "../apiRequest";
import { apiRoutes } from "../Constants/ApiRoutes";

export const getArticles = (id) => {
  if (!id) {
    return;
  }
  return getRequest(
    apiRoutes.categories +
      `/` +
      id +
      `/` +
      apiRoutes.getArticlesInCategory +
      `?sortBy=rank&sort=DESC`
  );
};

export const getParticularArticle = (id) => {
  if (!id) {
    return;
  }
  return getRequest(apiRoutes.getParticulatArticle + `/` + id);
};

export const articleSearch = (searchTerm) => {
  if (!searchTerm) {
    return;
  }
  return getRequest(
    apiRoutes.getParticulatArticle + `/${apiRoutes.articleSearch}` + searchTerm
  );
};
