import React, { useCallback, useState } from "react";
import styled from "styled-components";

const tooltipTextColor = "rgba(236, 239, 246, 1)";
const tooltipBackgroundColor = "rgba(42, 50, 62, 1)";
const tooltipArrowSize = "6px";

export const TooltipWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 0;
  position: relative;
  cursor: pointer;
`;

export const Icon = styled.img`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

export const TooltipTip = styled.div`
  position: absolute;
  cursor: default;
  border-radius: 4px;
  left: ${(props) =>
    props.className === "bottom-center"
      ? "50%"
      : props.className === "bottom-left"
      ? "0"
      : props.className === "top-center"
      ? "50%"
      : ""};
  top: ${(props) =>
    props.className === "bottom-center"
      ? "125%"
      : props.className === "bottom-left"
      ? "150%"
      : props.className === "top-left"
      ? "0px"
      : props.className === "top-right"
      ? "0px"
      : ""};
  transform: ${(props) =>
    props.className === "bottom-center"
      ? "translateX(-50%)"
      : props.className === "bottom-left"
      ? "translateX(-13px)"
      : props.className === "top-left"
      ? "translateX(-13px) translateY(-110%)"
      : props.className === "top-center"
      ? "translateX(-50%) translateY(-110%)"
      : props.className === "top-right"
      ? "translateX(-91%) translateY(-110%)"
      : props.className === "bottom-right"
      ? "translateX(-75%) translateY(75%)"
      : ""};
  padding: 5px 10px;
  color: ${tooltipTextColor};
  background: ${tooltipBackgroundColor};
  font-size: 12px;
  line-height: 18px;
  z-index: 100;
  height: auto;
  max-width: ${(props) => props.size || "250px"};
  white-space: pre-wrap;
  height: max-content;
  width: max-content;

  &:before {
    content: "";
    left: ${(props) =>
      props.className === "bottom-center"
        ? "50%"
        : props.className === "bottom-left"
        ? "20px"
        : props.className === "top-left"
        ? "20px"
        : props.className === "top-center"
        ? "50%"
        : ""};
    right: ${(props) =>
      props.className === "top-right"
        ? "10px"
        : props.className === "bottom-right"
        ? "10px"
        : ""};
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: ${tooltipArrowSize};
    margin-left: calc(${tooltipArrowSize} * -1);
  }

  &.bottom-center {
    &:before {
      bottom: 100%;
      border-bottom-color: ${tooltipBackgroundColor};
    }
  }

  &.bottom-left {
    &:before {
      bottom: 100%;
      border-bottom-color: ${tooltipBackgroundColor};
    }
  }

  &.bottom-right {
    &:before {
      bottom: 100%;
      border-bottom-color: ${tooltipBackgroundColor};
    }
  }

  &.top-left {
    &:before {
      transform: rotate(180deg);
      bottom: -12px;
      border-bottom-color: ${tooltipBackgroundColor};
    }
  }

  &.top-center {
    &:before {
      transform: rotate(180deg);
      bottom: -12px;
      border-bottom-color: ${tooltipBackgroundColor};
    }
  }

  &.top-right {
    &:before {
      transform: rotate(180deg);
      bottom: -12px;
      border-bottom-color: ${tooltipBackgroundColor};
    }
  }
`;

export default function TooltipIcon(props) {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = useCallback(() => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 200);
  }, []);

  const hideTip = useCallback(() => {
    clearInterval(timeout);
    setActive(false);
  }, []);

  return (
    <TooltipWrapper onMouseEnter={showTip} onMouseLeave={hideTip} {...props}>
      {props.image ? (
        <Icon
          width={props.width}
          height={props.height}
          src={props.image}
          alt={props.alt}
        />
      ) : (
        props.children
      )}
      {active && props.content && (
        <TooltipTip
          size={props.size}
          className={props.className ? props.className : "bottom-center"}
        >
          {props.content}
        </TooltipTip>
      )}
    </TooltipWrapper>
  );
}
