import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import FooterIcon from "../../images/footer.svg";
import FooterHoverIcon from "../../images/footerHover.svg";

const FooterWrapper = styled.footer`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;

  @media screen and (max-width: 800px) {
    margin-top: 40px;
    margin-bottom: 34px;
  }

  @media screen and (max-width: 500px) {
    margin-top: 30px;
    margin-bottom: 24px;
  }

  transition: margin 1s;
`;

const Label = styled.label`
  cursor: pointer;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: ${(props) => (props.active ? "#222222" : "#666b7d")};
`;

const Span = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  font-size: 13px;
  color: ${(props) => (props.active ? "#222222" : "#666b7d")};
`;

const FooterImage = styled.img`
  height: 16px;
  width: 16px;
  margin: 0 7px 0 0;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default function Footer() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const detectSize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [screenWidth]);

  let timeout;
  const [active, setActive] = useState(false);

  const showTip = useCallback(() => {
    timeout = setTimeout(() => {
      setActive(true);
    }, 0);
  }, []);

  const hideTip = useCallback(() => {
    clearInterval(timeout);
    setActive(false);
  }, []);

  return (
    <FooterWrapper>
      <Wrapper
        onMouseEnter={showTip}
        onMouseLeave={hideTip}
        onClick={() => {
          window.open("https://hiverhq.com/", "_blank");
        }}
      >
        <FooterImage src={active ? FooterHoverIcon : FooterIcon} />

        <Label active={active}>
          Powered by <Span active={active}>Hiver</Span>
        </Label>
      </Wrapper>
    </FooterWrapper>
  );
}
