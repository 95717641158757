import React, { Fragment, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { trimText } from "../../Constants/utility";
import { CategoryContext, KBContext, SearchContext } from "../../Context";
import searchArticle from "../../images/searchArticle.svg";
import rightIcon from "../../images/rightIcon.svg";

const PageWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 180px;
  margin-top: 48px;
  box-sizing: border-box;
  overflow: hidden;

  @media screen and (max-width: 1100px) {
    margin: 0 90px;
    margin-top: 28px;
  }

  @media screen and (max-width: 800px) {
    margin: 0 50px;
    margin-top: 18px;
  }

  @media screen and (max-width: 500px) {
    margin: 0 24px;
    margin-top: 10px;
  }

  transition: margin 1s;
`;

const CountHeader = styled.div`
  height: 28px;
  font-size: 20px;
  line-height: 28px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  color: #313c4b;
`;

const ArticleWrapper = styled.div`
  margin-top: 33px;
  display: grid;
  row-gap: 33px;
  padding-right: 76px;

  @media screen and (max-width: 764px) {
    padding-right: 0;
  }

  transition: padding 1s;
`;

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
`;

const ArticleImageContainer = styled.div`
  min-width: 64px;
  height: 64px;
  cursor: pointer;
`;

const SearchIconImage = styled.img`
  min-width: 24px;
  height: 24px;
`;

const Article = styled.div`
  width: 100%;
  margin-left: 21px;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
`;

const ArtcleHeader = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.138889px;
  color: #222222;
  cursor: pointer;
  margin-right: 20px;
  cursor: pointer;

  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  // &:hover {
  //   overflow: auto;
  //   text-overflow: initial;
  // }

  &.searchTitle > * {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.138889px;
    color: #222222;
  }
`;

const ArticleBody = styled.label`
  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #666b7d;
  cursor: pointer;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  .searchContent > *,
  strong {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #666b7d;

    transition: line-height 1s;
  }

  // &:hover {
  //   overflow: auto;
  //   text-overflow: initial;
  // }

  @media screen and (max-width: 1366px) {
    line-height: 22px;
  }

  transition: line-height 1s;
`;

const ArticleHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
`;

const CategoryTitle = styled.label`
  border: 1px solid #8f97b2;
  box-sizing: border-box;
  border-radius: 26px;
  font-size: 14px;
  line-height: 21px;
  color: #666b7d;
  padding: 1px 10px;

  cursor: pointer;
  max-width: 150px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  // &:hover {
  //   overflow: auto;
  //   text-overflow: initial;
  // }
`;

const BreadCrumContainer = styled.div`
  min-width: 184px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 32px 0;

  @media screen and (max-width: 1100px) {
    margin: 24px 0;
  }

  @media screen and (max-width: 500px) {
    margin: 12px 0;
  }

  transition: margin 1s;
`;

const BreadCrum = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #313c4b;
  margin-right: 10px;
  cursor: pointer;
  // &:hover {
  //   text-decoration: underline;
  // }
`;

const RightImage = styled.img`
  height: 9.55px;
  width: 5.83px;
  margin-right: 10px;
`;

const SubmitIssueText = styled.span`
  color: ${(props) => props.color};
  font-weight: 500;
  cursor: pointer;

  // &:hover{
  //   text-decoration: underline;
  // }
`;

const NoResults = styled.div`
  margin-top: 24px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #666b7d;
`;

const ImageContainer = styled.div`
  width: 64px;
  height: 64px;
  background-image: url(${searchArticle});
  background-repeat: no-repeat;
  background-position: center;
  background-color: #D6E0EB;
  border-radius: 50%;
  cursor: pointer;
`;

function App() {
  const { searchList, setSearchInput, searchInput, setSearchList } =
    useContext(SearchContext);
  const { categoryList, setSelectedCategory } = useContext(CategoryContext);
  const { data } = useContext(KBContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const renderCount = () => {
    if (searchList.length === 0) {
      return <>No results for "{searchInput}"</>;
    } else if (searchList.length === 1) {
      return <>{searchList.length} article found</>;
    } else {
      return <>{searchList.length} articles found</>;
    }
  };

  function extractContent(s, space) {
    var span = document.createElement("span");
    span.innerHTML = s;
    if (space) {
      var children = span.querySelectorAll("*");
      for (var i = 0; i < children.length; i++) {
        if (children[i].textContent) children[i].textContent += " ";
        else children[i].innerText += " ";
      }
    }
    return [span.textContent || span.innerText].toString().replace(/ +/g, " ");
  }

  const splitMatchedText = (str, content) => {
    const regExp = new RegExp(str, "gi");
    return content.replace(regExp, (match) => `<mark>${match}</mark>`);
  };

  return (
    <PageWrapper bannerLeft={pathname.slice(1) === ""}>
      <BreadCrumContainer>
        <BreadCrum
          onClick={() => {
            setSearchInput("");
            setSearchList([]);
            navigate("/");
          }}
        >
          Home
        </BreadCrum>
        <RightImage src={rightIcon} alt="righticon" />
        <BreadCrum>{"Search results"}</BreadCrum>
      </BreadCrumContainer>
      <CountHeader>{renderCount()}</CountHeader>
      {searchList?.length === 0 && (
        <NoResults>
          {data?.enableHelpForm ? (
            <>
              Please{" "}
              <SubmitIssueText
                color={data.brandColor}
                onClick={() => navigate("/submit-issue")}
              >
                submit an Issue
              </SubmitIssueText>{" "}
              if you can’t find what you are looking for
            </>
          ) : (
            <>We couldn’t find what you are looking for.</>
          )}
        </NoResults>
      )}
      <ArticleWrapper>
        {searchList?.map((item) => {
          const res = categoryList.find(
            (cat) => cat.categoryId === item?.categoryId
          );
          return (
            <ArticleContainer
              key={item.articleId}
              onClick={() => {
                setSearchInput("");
                if (res) {
                  setSelectedCategory(res);
                }
                navigate(`/${res.slug}/${item.slug}`);
              }}
            >
              <ArticleImageContainer>
                <ImageContainer />
              </ArticleImageContainer>
              <Article>
                <ArticleHeaderContainer>
                  <ArtcleHeader
                    className="searchTitle"
                    dangerouslySetInnerHTML={{
                      __html: splitMatchedText(
                        searchInput,
                        extractContent(item?.title, true)
                      ),
                    }}
                  />
                  <CategoryTitle>{res?.title}</CategoryTitle>
                </ArticleHeaderContainer>
                <ArticleHeaderContainer>
                  <ArticleBody
                    className="searchContent"
                    dangerouslySetInnerHTML={{
                      __html: splitMatchedText(
                        searchInput,
                        extractContent(item?.content, true)
                      ),
                    }}
                  />
                </ArticleHeaderContainer>
              </Article>
            </ArticleContainer>
          );
        })}
      </ArticleWrapper>
    </PageWrapper>
  );
}

export default App;
