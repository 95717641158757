import React from "react";
import styled from "styled-components";
import { trimText } from "../../Constants/utility";
import getStarted from "../../images/getStarted.svg";
import TooltipIcon from "../StyledComponents/Tooltip";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 245px;
  width: 303px;
  border-radius: 10px;
  padding: 0 30px;
  box-sizing: border-box;
  background-color: #f9f9f9;

  @media screen and (max-width: 800px) {
    width: 327px;
  }

  &:hover {
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
  }
`;

const WidgetHead = styled.div`
  margin: 53.5px 0 8px 0;
`;

const WidgetTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.138889px;
  color: #222222;
  text-align: center;
`;

const WidgetContent = styled.label`
  margin: 6px 0 0 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 173%;
  letter-spacing: 0px;
  text-align: center;
  color: #313c4b;
  cursor:pointer;
`;

export default function CategoryWidget(props) {
  const Image_Base_Url = process.env.REACT_APP_IMAGE_BASE_URL;

  const CategoryIcon = (str) => {
    if (str === "getting-started-icon") {
      return (
        <img
          style={{ width: "40px", height: "40px" }}
          alt="categoryIcon"
          src={getStarted}
        />
      );
    }else if (str?.includes("media")) {
      return (
        <img
          style={{ width: "40px", height: "40px" }}
          alt="categoryIcon"
          src={Image_Base_Url + str}
        />
      );
    } else {
      return (
        <span
          style={{ fontSize: "40px", height: "40px", color: props.color }}
          className="material-icons-outlined">
          {str}
        </span>
      );
    }
  };

  return (
    <Wrapper>
      <WidgetHead>{CategoryIcon(props.icon)}</WidgetHead>
      <WidgetTitle>{props?.title}</WidgetTitle>
      {props?.description.length > 60 ? (
        <TooltipIcon
          className="bottom-center"
          content={props?.description}
          size="250px"
        >
          <WidgetContent>{trimText(props?.description, 60)}</WidgetContent>
        </TooltipIcon>
      ) : (
        <WidgetContent>{props?.description}</WidgetContent>
      )}
    </Wrapper>
  );
}
