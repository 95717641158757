import styled from "styled-components";
import submittedSuccessfully from "../../images/submittedSuccessfully.svg";
import { StyledButton } from "../StyledComponents/StyledComponents";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SearchContext } from "../../Context/index";
import { lightOrDark } from "../../Constants/utility";

const SubmitSuccessWrapper = styled.section`
  box-sizing: border-box;
  width: 40%;
  margin: 0 auto;
  margin-top: 68px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 780px) {
    width: auto;
  }

  margin-top: 35px;

  @media screen and (max-width: 1250px) {
    margin: 0 200px;
    margin-top: 35px;
  }

  @media screen and (max-width: 1100px) {
    margin: 0 100px;
    margin-top: 35px;
  }

  @media screen and (max-width: 768px) {
    margin: 0 50px;
    margin-top: 35px;
  }

  @media screen and (max-width: 500px) {
    margin: 0 24px;
    margin-top: 35px;
  }

  transition: margin 1s;
`;

const SubmitImage = styled.img`
  margin: 65px 0 0 0;
  width: 40px;
  height: 40px;
`;

const Text = styled.label`
  margin: 22px 0 48px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #313c4b;
  text-align: center;
`;

const Button = styled(StyledButton)`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: 150px;
  height: 48px;
`;

export default function SubmitSuccess({ brandData }) {
  const { setSearchInput } = useContext(SearchContext);
  const navigate = useNavigate();
  return (
    <SubmitSuccessWrapper>
      <SubmitImage src={submittedSuccessfully} />
      <Text>{brandData?.helpPageMessage}</Text>
      <Button
        textColor={
          brandData?.brandColor && lightOrDark(brandData?.brandColor) === "dark"
            ? "#FFFFFF"
            : "#222222"
        }
        color={brandData.brandColor}
        primary
        onClick={() => {
          setSearchInput("");
          navigate("/");
        }}
      >
        Back to Home
      </Button>
    </SubmitSuccessWrapper>
  );
}
