import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CategoriesSider from "../../Components/SideBar/CategoriesSider";
import { CategoryContext, KBContext } from "../../Context";
import { handlePageVisitData } from "../../utils";

export default function CategoryList({ focus }) {
  const { categoryList, setSelectedCategory, selectedCategory } = useContext(CategoryContext);
  const { pathname } = useLocation();
  const [visible, setVisible] = useState(true);
  const { data: { isInternal } } = useContext(KBContext);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const detectSize = () => {
    if (window.innerWidth < 800) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [screenWidth]);

  const navigate = useNavigate();
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    navigate(`/${category.slug}`);
    focus();
  };

  useEffect(() => {
    handlePageVisitData(window.location, isInternal);
    const res = categoryList.find((cat) => cat.slug === pathname.split("/")[1]);
    if (res) {
      setSelectedCategory(res);
    }
  }, [pathname, categoryList, setSelectedCategory]);

  if (visible) {
    return (
        <CategoriesSider
          categories={categoryList}
          pathname={pathname}
          handleCategoryClick={handleCategoryClick}
        />
    );
  } else {
    return "";
  }
}
