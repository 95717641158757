import { getRequest } from "../apiRequest";
import { apiRoutes } from "../Constants/ApiRoutes";

export const getCategory = () => {
  return getRequest(apiRoutes.categories, {
    limit: 500,
    sortBy: "rank",
    sort: "DESC",
  });
};
