import React, { useContext, useState } from "react";
import Layout from "../../Components/Layout";
import SubmitIssue from "../../Components/SubmitIssue/SubmitIssue";
import SubmitSuccess from "../../Components/SubmitIssue/SubmitSuccess";
import { validateEmail } from "../../Constants/utility";
import { KBContext } from "../../Context";
import { SubmitIssueService } from "../../Service/SubmitIssueService";
import ScrollToTop from "../../Components/ScrollToTop";

export default function SubmitAnIssue() {
  const { data } = useContext(KBContext);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    query: "",
  });

  const [formDataError, setFormDataError] = useState({
    nameError: "",
    emailError: "",
    subjectError: "",
    queryError: "",
  });

  const [submitted, setSubmitted] = useState(false);
  const [btnClick, setBtnClick] = useState(false);

  const handleSubmit = async () => {
    if (!btnClick) {
      if (formValidation()) {
        return;
      }
      setBtnClick(true);
      const res = await SubmitIssueService(formData);
      if (res.status === 201) {
        setBtnClick(false);
        setSubmitted(true);
      }
    }
  };

  const handleTittleChange = (e) => {
    if (e.target.value === "") {
      setFormDataError((prevState) => ({
        ...prevState,
        nameError: "This field is required",
      }));
    } else {
      setFormDataError((prevState) => ({ ...prevState, nameError: "" }));
    }
    setFormData((prevState) => ({ ...prevState, name: e.target.value }));
  };

  const handleEmailChange = (e) => {
    if (e.target.value === "") {
      setFormDataError((prevState) => ({
        ...prevState,
        emailError: "This field is required",
      }));
    } else {
      setFormDataError((prevState) => ({ ...prevState, emailError: "" }));
    }
    setFormData((prevState) => ({ ...prevState, email: e.target.value }));
  };

  const handleSubjectChange = (e) => {
    if (e.target.value.length > 30) {
      return;
    }
    if (e.target.value === "") {
      setFormDataError((prevState) => ({
        ...prevState,
        subjectError: "This field is required",
      }));
    } else {
      setFormDataError((prevState) => ({ ...prevState, subjectError: "" }));
    }
    setFormData((prevState) => ({ ...prevState, subject: e.target.value }));
  };

  const handleDescriptionChange = (e) => {
    if (e.target.value.length > 200) {
      return;
    }
    if (e.target.value === "") {
      setFormDataError((prevState) => ({
        ...prevState,
        queryError: "This field is required",
      }));
    } else {
      setFormDataError((prevState) => ({ ...prevState, queryError: "" }));
    }

    setFormData((prevState) => ({ ...prevState, query: e.target.value }));
  };

  const ifError = () => {
    for (const property in formDataError) {
      if (formDataError[property]) {
        return true;
      }
    }
    return false;
  };

  const formValidation = () => {
    return (
      ifError() ||
      formData.name === "" ||
      formData.email === "" ||
      formData.query === "" ||
      formData.subject === "" ||
      !validateEmail(formData.email)
    );
  };

  const checkEmail = () => {
    const res = validateEmail(formData.email);
    if (!res) {
      setFormDataError((prevState) => ({
        ...prevState,
        emailError: "Please enter a valid email",
      }));
    }
    return res;
  };

  return (
    <Layout>
      <ScrollToTop />
      {!submitted ? (
        <SubmitIssue
          formData={formData}
          setFormData={setFormData}
          checkEmail={checkEmail}
          formValidation={formValidation}
          handleTittleChange={handleTittleChange}
          handleDescriptionChange={handleDescriptionChange}
          handleEmailChange={handleEmailChange}
          handleSubjectChange={handleSubjectChange}
          handleSubmit={handleSubmit}
          formDataError={formDataError}
          setFormDataError={setFormDataError}
          brandColor={data?.brandColor}
          textColor={data?.bannerTextColor}
          btnClick={btnClick}
          setBtnClick={setBtnClick}
        />
      ) : (
        <SubmitSuccess brandData={data} />
      )}
    </Layout>
  );
}
