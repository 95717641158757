import React, { useContext } from "react";
import styled from "styled-components";
import Dash from "../images/dashboardIcon.svg";
import dashArrow from "../images/dashArrow.svg";

const Wrapper = styled.div`
  width: 100%;
  height: 40px;
  background: #1473e6;
  position: fixed;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  &.hidden {
    bottom: 0;
  }
   transition: bottom 1s;
`;

const Label = styled.label`
  font-family: "Inter";
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.138889px;
  color: #ffffff;
  cursor: pointer;
`;

const DashIcon = styled.img`
  height: 16px;
  width: 16px;
  margin: 0 12px;
  cursor: pointer;
`;
const Wrap = styled.div`
  cursor: pointer;
  display: flex;
`;

const AdminUrl = process.env.REACT_APP_IMAGE_BASE_URL;

export default function AdminFooter() {
  return (
    <Wrapper> 
      <Wrap
        onClick={() => {
          window.open(AdminUrl, "_blank");
        }}
      >
        <DashIcon src={Dash} alt="dashboard" />
        <Label>Go to Dashboard</Label>
        <DashIcon src={dashArrow} alt="arrow" />
      </Wrap>
    </Wrapper>
  );
}
