import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
    Label,
    StartedWrapper,
    ImageWrapper,
    SecondWrapper,
    Title,
    LogoWrapper,
    LoginButton,
    StyledInput,
    ErrorMessage,
    FlexBox,
    LogoImage
} from "./styled.js";
import loginBanner from "../../images/loginBanner.svg";
import {
    LOG_IN,
    EMAIL_PLACEHOLDER,
    LOG_IN_DESCRIPTION,
    WELCOME,
    DIFF_EMAIL_ERROR,
    NO_ACCESS_ERROR,
    LOGO_IMG
} from "./constants";
import { validateEmail } from "../../Constants/utility.js";
import { KBContext, AutherContext } from "../../Context";
import { getLogo } from "./utils.js";

export default function LoginPage() {
    const { data : kbData } = useContext(KBContext);
    const [emailAddress, setEmailAddress] = useState("");
    const [showLoginError, setShowLoginError] = useState(false);
    const {
      setRefetchToken,
      setRedirectPage,
      setEmail,
      showError: showDifferentEmailError,
      hasPermission,
      loginInitiated,
      setLoginInitiated,
    } = useContext(AutherContext);
    const navigate = useNavigate();

    const handleLoginButton = () => {
        setRefetchToken(true);
        setRedirectPage(true);
        setEmail(emailAddress);
        setLoginInitiated(false);
    };

    useEffect(() => {
        setShowLoginError(loginInitiated && (showDifferentEmailError || !hasPermission));
    }, [hasPermission, loginInitiated, showDifferentEmailError]);

    useEffect(() => {
        const { isInternal } = kbData;
        if (isInternal !== undefined) {
            if ((isInternal && hasPermission) || !isInternal) {
                setLoginInitiated(false);
                navigate("/");
            }
        }
    }, [kbData, hasPermission, setLoginInitiated, navigate]);

    const handleOnChange = (e) => {
        e?.stopPropagation();
        const { value } = e.target || {};
        setEmailAddress(value ?? "");
    };

    const isDisabled = !validateEmail(emailAddress);

    const handleKeyPress = (e) => {
        e?.stopPropagation();
        if (e.key === "Enter" && !isDisabled) {
            handleLoginButton();
        }
    };

    let logo = getLogo(kbData);
    logo = logo ? LOGO_IMG.replaceAll('{{LOGO}}', logo) : false;

    return (
        <StartedWrapper>
            <ImageWrapper>
                <img alt='getStartedScreen' src={loginBanner} />
            </ImageWrapper>
            <SecondWrapper>
                {
                    logo &&
                    <LogoWrapper>
                        <LogoImage alt="Logo" src={logo} />
                    </LogoWrapper>
                }
                <Title>{WELCOME}</Title>
                <Label>{LOG_IN_DESCRIPTION}</Label>
                <StyledInput
                    className={showLoginError ? 'error' : ''}
                    onKeyDown={handleKeyPress}
                    placeholder={EMAIL_PLACEHOLDER}
                    value={emailAddress}
                    onChange={handleOnChange}
                />
                {showLoginError && (
                    <FlexBox>
                        <ErrorMessage>
                            {showDifferentEmailError ? DIFF_EMAIL_ERROR : NO_ACCESS_ERROR}
                        </ErrorMessage>
                    </FlexBox>
                )}
                <LoginButton
                    className={isDisabled ? 'disabled' : ''}
                    aria-label={LOG_IN}
                    onClick={handleLoginButton}
                >
                    {LOG_IN}
                </LoginButton>
            </SecondWrapper>
        </StartedWrapper>
    );
}
