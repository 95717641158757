import styled from "styled-components";

export const Wrapper = styled.div`
  margin: ${(props) => props.margin || "0"};
  cursor: pointer;
  position: relative;
  height: ${(props) => props.size || "20px"};
  width: ${(props) => props.size || "20px"};
`;

export const CenterWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const CrossLineInverse = styled.div`
  position: absolute;
  height: 14px;
  width: 1.5px;
  margin-top: -7px;
  background-color: ${(props) => props.background || "#28476A"};
  transform: rotate(45deg);
  z-index: 1;
`;

export const CrossLine = styled.div`
  position: absolute;
  height: 14px;
  width: 1.5px;
  margin-top: -7px;
  background-color: ${(props) => props.background || "#28476A"};
  transform: rotate(-45deg);
  z-index: 2;
`;