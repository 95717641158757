export const ROLES = {
    ADMIN: "ADMIN",
    MEMBER: "MEMBER",
    VIEWER: "VIEWER",
    UNAUTHORIZED: "UNAUTHORIZED",
};

export const DASHBOARD_ALLOWED_ROLES = [ROLES.ADMIN, ROLES.MEMBER];

export const INTERNAL_KB_ALLOWED_ROLES = [ROLES.ADMIN, ROLES.MEMBER, ROLES.VIEWER];

export const KB_TYPE = {
    INTERNAL: 'internal',
    EXTERNAL: 'external',
}

